import { titleCase } from '../utils/format'
export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.metaKeywords
        },
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.title
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: this.metaUrl
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: 'Stomping Elephants'
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'Product'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.imageUrl
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.metaDescription
        }
        // {
        //   hid: 'og:admins',
        //   name: 'og:admins',
        //   content: 'abbyjane.walsh,nicole.hatfield'
        // },
        // {
        //   hid: 'og:app_id',
        //   name: 'og:app_id',
        //   content: '143314783062725'
        // }
      ]
    }
  },
  computed: {
    title() {
      const routeName = this.$route.path.split('/').reverse()[0]
      return this.metaDataExists
        ? this.pageData.title
        : titleCase(routeName, '-')
    },
    pageData() {
      return this.$api.site.static[this.$route.name]
    },
    metaDataExists() {
      const api = this.$api.site.static[this.$route.name]
      return api !== null && api !== undefined
    },
    metaDescription() {
      return this.metaDataExists ? this.pageData.description : ''
    },
    metaKeywords() {
      return this.metaDataExists ? this.pageData.keywords.join(', ') : ''
    },
    staticContent() {
      return this.metaDataExists && this.pageData.content
        ? this.pageData.content
        : []
    },
    contentKeys() {
      return this.metaDataExists && this.pageData.content
        ? Object.keys(this.pageData.content)
        : []
    },
    metaUrl() {
      const base = this.$store.state.clientBaseUrl
      const page = this.$store.state.currentPage
      return `${base}${page}`
    }
  }
}
