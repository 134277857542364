<template>
  <b-link :to="to">
    <div
      class="infobox__outer"
      :class="outerClass"
    >
      <div class="infobox__inner">
        <slot>
          <div class="infobox__icon">
            <img src="~/assets/img/new-in-store-icon.png" class="img-fluid">
          </div>
          <div class="infobox__text">
            <div>
              <h1 class="font-weight-bold align-top mb-0">
                NEW
              </h1>
            </div>
            <div class="">
              <p>in store</p>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </b-link>
</template>

<script>
export default {
  props: {
    outerClass: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    }
  }
}
</script>
