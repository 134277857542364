<template>
  <section>
    <b-row id="carousel__row" no-gutters>
      <b-col no-gutters>
        <div>
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1200"
            img-height="520"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide v-for="image in carouselImages" :key="'carousel-image-' + image.position">
              <template v-slot:img>
                <span v-if="image.link.length > 0">
                  <b-link v-if="!image.isHref" :to="image.link">
                    <img
                      class="d-block img-fluid w-100"
                      width="1200"
                      height="520"
                      :src="image.image"
                      :alt="image.title"
                    >
                  </b-link>
                  <b-link v-else :href="image.link" target="_blank">
                    <img
                      class="d-block img-fluid w-100"
                      width="1200"
                      height="520"
                      :src="image.image"
                      :alt="image.title"
                    >
                  </b-link>
                </span>
                <img
                  v-else
                  class="d-block img-fluid w-100"
                  width="1200"
                  height="520"
                  :src="image.image"
                  :alt="image.title"
                >
              </template>
            </b-carousel-slide>
            <b-carousel-slide
              v-if="carouselImages.length === 0"
              img-blank
              img-alt="Blank image"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <FilterBar :inline-search="true" :open-filters="true" class="d-block" />
        <!-- <DropdownSmall class="d-block d-md-none" /> -->
      </b-col>
    </b-row>
    <b-row class="infobox__row">
      <div class="infobox__container">
        <Infobox to="/new-in-store" />
      </div>
      <div class="infobox__container">
        <Infobox
          outer-class="dangle"
          to="/shop/other"
        >
          <div class="infobox__icon">
            <img src="~/assets/img/other-products-icon.png" class="img-fluid">
          </div>
          <div class="infobox__text">
            <p class="mb-0">
              Check out our
            </p>
            <div>
              <h2 class="font-weight-bold mb-0">
                other
              </h2>
              <h2 class="font-weight-bold mb-0">
                products
              </h2>
            </div>
          </div>
        </Infobox>
      </div>
      <div class="infobox__container">
        <Infobox
          outer-class="shipping"
          :to="shippingRoute"
        >
          <div class="infobox__icon">
            <img src="~/assets/img/Delivery-Icon.png" class="img-fluid">
          </div>
          <div class="infobox__text">
            <div class="align-top">
              <p class="font-weight-bold">
                FREE shipping
              </p>
            </div>
            <div>
              <p class="mx-1">
                on orders $59 and over in Australia*
              </p>
            </div>
          </div>
        </Infobox>
      </div>
    </b-row>
    <!-- <b-row size="lg">
      <b-col size="lg">
        <Alerts />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import Infobox from '~/components/Infobox'
import FilterBar from '~/components/FilterBar'
import siteMeta from '~/mixins/siteMeta'
// import Alerts from '~/components/Alerts'
// import HaveYouSeen from '~/components/HaveYouSeen'
// import DropdownSmall from '~/components/DropdownSmall'

import { mapState } from 'vuex'

export default {
  components: {
    Infobox,
    FilterBar
    // 'have-you-seen': HaveYouSeen
    // Alerts
    // DropdownSmall
  },
  mixins: [siteMeta],
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  computed: {
    // ...mapState('products', ['products', 'haveYouSeen']),
    ...mapState('products', {
      products: state => state.products
    }),
    ...mapState('sitenav', {
      studEarringsRoute: state => state.navItems.studEarrings.route,
      dangleEarringsRoute: state => state.navItems.dangleEarrings.route,
      shippingRoute: state => state.navItems.shippingAndReturns.route
    }),
    carouselImages() {
      const images = this.$store.state.carouselImages
      if (images === null) {
        return []
      }
      return images
        .map(i => {
          return {
            link: i.attributes.link,
            image: i.attributes.slide_image,
            position: i.attributes.position,
            title: i.attributes.title,
            isHref: this.isHref(i.attributes.link)
          }
        })
        .sort(i => {
          return i.position - i.position
        })
    },
    imageUrl() {
      return `${
        this.$store.state.clientBaseUrl
      }/_nuxt/assets/img/Stompint-Elephants-Logo.png`
    }
  },
  async fetch({ store, app: { $normalizer } }) {
    store.commit('products/RESET_FILTERS')
    await store.dispatch('products/haveYouSeen')
    await store.dispatch('setCarouselImages')
  },
  mounted() {
    if (this.$route.query.login) {
      this.showLogin()
      this.$router.replace('/')
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    isHref(link) {
      return link.startsWith('https') || link.startsWith('www')
    }
  }
}
</script>
<style lang="scss">
.carousel-caption {
  a {
    color: white;
    text-decoration: none;
  }
}
@media (max-width: 768px) {
  #carousel__row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
</style>
